<template>
  <v-container id="newsevents" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/newsevents/news-events-bg.jpg') + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{$t("news")+' & '+$t("events")}}</h1>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-2">
          <v-col cols="12" sm="5" order="2" order-sm="1" class="ma-0 py-0">
            <v-sheet
              elevation="0"
              class="ma-auto ma-sm-0 mr-sm-auto pa-0"
            >
              <v-chip-group
                mandatory
                multiple
                show-arrows
                v-model="activefilters"
                active-class="custom-accent"
              >
                <v-chip
                  v-for="filter in filters"
                  :key="filter"
                  :value="filter"
                  outlined
                >
                  {{ $t(filter) }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="7" order="1" order-sm="2" class="ma-0 pa-1">
            <v-text-field
              v-model="Search.SearchTerm"
              :label="$t('search')"
              :placeholder="$t('search')"
              class="search-box ma-auto ma-sm-0 ml-sm-auto pa-0"
              color="custom-accent"
              rounded
              clearable
              outlined
              dense
              @click:clear="Search.SearchTerm='';search(Search.SearchTerm)"
              @keyup="search(Search.SearchTerm)"
              append-icon="mdi-magnify"
              hide-details
              return-object
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10" lg="8" class="ma-auto">
            <transition name="fade" mode="out-in" v-if="blog_loading || Search.SearchLoading">
              <v-row class="px-8 ma-0" justify="center">
                <v-col
                  v-for="i in itemsperpage"
                  :key="i"
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-skeleton-loader
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="Search.SearchResult.length==0 && !Search.SearchLoading && !blog_loading">
              <v-row class="text-center pa-12 ma-auto" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}<span v-if="Search.SearchTerm">{{$t("resultfor")}} "{{Search.SearchTerm}}"</span>.</i></v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="blogs.length && Search.SearchResult.length && !Search.SearchLoading && !blog_loading">
              <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
                <v-col
                  v-for="(item, i) in Search.SearchResult.slice(
                    itemsperpage * (page - 1),
                    itemsperpage * page
                  )"
                  :key="i"
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-card
                    class="elevation-0"
                    outlined
                    @click="ViewBlog(item.id)"
                  >
                    <div
                      class="text-center text-sm-left"
                    >
                      <div>
                        <v-card-title class="mb-2 custom-accent--text"><v-badge left :content="$t(item.category)" color="custom-accent"><span v-line-clamp="1">{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span></v-badge></v-card-title>
                        <v-card-subtitle class="subtitle-2">{{$t("by")}} <span class="text-capitalize">{{$getObjectById(users,item.author).name}}</span> | {{item.created_at | formatDate}}</v-card-subtitle>
                          <v-card-text class="py-0 custom-secondary--text"><span class="v-html-line-clamp-2" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-card-text>
                          <v-card-actions>
                          <v-chip v-if="item.image" color="secondary" class="mr-auto"><v-icon class="mr-2" dark>mdi-image-area</v-icon><i>{{$t("imageavailable")}}</i></v-chip>
                          <v-btn color="custom-accent" class="ml-auto" text @click.stop="ViewBlog(item.id)">{{$t("viewmore")}}</v-btn>
                        </v-card-actions>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </transition>
            <v-row class="pa-0 ma-0 mb-4">
              <v-col v-if="Math.ceil(Search.SearchResult.length / itemsperpage)>1">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(Search.SearchResult.length / itemsperpage)"
                  color="custom-accent"
                  :total-visible="7"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-dialog v-model="blogdetailsoverlay" width="1100px" scrollable>
          <v-card class="pa-2 overflow-hidden" v-if="activeblog">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{
                $i18n.locale == "en"
                  ? activeblog.titleEN
                  : activeblog.titleFR
              }}</span><v-spacer></v-spacer>
              <v-tooltip left color="custom-secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn absolute right top
                  style="z-index: 3;top:-1px;right:-2px"
                  v-bind="attrs"
                  v-on="on"
                  :title="$t('close')"
                  @click="blogdetailsoverlay = false"
                  icon
                >
                  <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("close") }}</span>
            </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="subtitle-2 custom-secondary--text pa-0 ma-0 mx-4" v-if="blogdetailsoverlay"><v-badge inline :content="$t(activeblog.category)" color="custom-accent"></v-badge>{{$t("by")}} <span class="text-capitalize">{{$getObjectById(users,activeblog.author).name}}</span> | {{activeblog.created_at | formatDate}}</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <v-card-text class="subtitle-2 custom-secondary--text">
              <v-img v-if="activeblog.image"
                :src="require('../../assets/'+activeblog.image)"
                :alt="activeblog.alt"
                aspect-ratio="1"
                class="ma-auto mb-8"
                max-height="600"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span v-html="$i18n.locale == 'en'? activeblog.descriptionEN: activeblog.descriptionFR"></span>
              <span class="text-uppercase text-h6 custom-accent--text">{{$t("leaveacomment")}}</span>
              <contact-us-form class="my-5" :isDark="false" :type="'newsevents-'+activeblog.id"></contact-us-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/ContactUsForm.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "NewsEvents",

  data() {
    return {
      Search:{
        SearchTerm:'',
        SearchBox:false,
        SearchLoading:false,
        SearchResult:[],
      },
      filters:[
        'news','events','archived'
      ],
      activefilters:[],
      index: 0,
      blogdetailsoverlay: false,
      activeblog: null,
      page: 1,
      itemsperpage: 4,
    };
  },
  components: {
    'contact-us-form':contactusform,
  },
  async created() {
    await this.retrieveUsers();
    await this.retrieveBlogs().then(()=>{
      this.blogs.reverse();
      this.Search.SearchResult=this.blogs;
      this.activefilters=[
          'news','events'
        ];
    });
    if(this.$route.params.search && this.$route.params.blogid){
      this.activefilters=this.filters;
      this.Search.SearchTerm=this.$route.params.search;
      this.search(this.$route.params.search);
      this.ViewBlog(this.$route.params.blogid);
    }
  },
  computed: {
    ...mapGetters({users:'getUsers'}),
    ...mapGetters('blog', {
      blogs:'getBlogs'
    }),
    ...mapState("blog", {
      blog_loading: "loading"
    }),
  },
  watch: {
    activefilters(){
      this.search(this.Search.SearchTerm);
    },
    $route() {
      if(this.$route.params.search && this.$route.params.blogid){
        this.activefilters=this.filters;
        this.search(this.$route.params.search);
        this.ViewBlog(this.$route.params.blogid);
      }
    },
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    search(item){
      this.Search.SearchLoading=true
      this.Search.SearchResult = '';
      if(item)
        this.Search.SearchResult = this.blogs.filter(blog => {
          if(this.$i18n.locale=='en'){
          return this.activefilters.includes(blog.category?.toLowerCase()) && (blog.titleEN?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionEN?.toLowerCase().includes(item.toLowerCase())) ;
          }
          else{
          return this.activefilters.includes(blog.category?.toLowerCase()) && (blog.titleFR?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionFR?.toLowerCase().includes(item.toLowerCase())) ;
          }
        });
      else
        this.Search.SearchResult=this.blogs.filter(blog => {
          return this.activefilters.includes(blog.category.toLowerCase())
          }
        );
      setTimeout(()=>{
        this.Search.SearchLoading=false;
      },200)
      
    },
    ViewBlog(id) {
      let blog = this.Search.SearchResult.filter(blog=>{
        return blog.id==id;
      })[0];
      this.blogdetailsoverlay = true;
      this.activeblog = blog;
    },

    ...mapActions(["retrieveUsers"]),
    ...mapActions("blog", ["addBlog","retrieveBlogs"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/newsevents.css");
</style>